
import StoreConfigurationModal from "@/components/modals/StoreConfigurationModal";
import stores from "@/services/stores";
import Sensor from "@/components/Sensor/sensor.vue";
import resources from "@/services/resources";
import Vue from "vue";
import store from '@/store/';

export default {
  components: { StoreConfigurationModal, Sensor },
  data() {
    return {
      exportBtn: false,
      allExport: false,
      selectedSensor: [],
      openModel: false,
      allStoresData: [],
      columns: [
        {
          name: "storeName",
          align: "left",
          label: this.$store.state.user.translate.name,
          field: (row) => row.title,
          // format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "storeNumber",
          align: "left",
          label: this.$store.state.user.translate.store_number,
          field: "storeNumber",
          sortable: true,
        },
        {
          name: "region",
          label: this.$store.state.user.translate.region,
          field: "region",
        },
        {
          name: "district",
          label: this.$store.state.user.translate.district,
          field: "district",
        },
        {
          name: "prefecture",
          label: this.$store.state.user.translate.prefecture,
          field: "prefecture",
        },
        {
          name: "city",
          label: this.$store.state.user.translate.city,
          field: "city",
        },
        {
          name: "status",
          label: this.$store.state.user.translate.status,
          field: "status",
        },
        // {
        //   name: "sensor",
        //   label: this.$store.state.user.translate.user_sensor,
        //   field: "sensor",
        // },
        {
          name: "actions",
          label: this.$store.state.user.translate.actions,
          field: "action",
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
      confirm: false,
      selectedStore: [],
      loading: false,
      visible: false,
      countryOptions: [],
      allRegions: [],
      allCities: [],
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.allStoresData.length / this.pagination.rowsPerPage);
    },
  },
  watch: {
    allExport(newValue) {
      if (newValue) {
        this.selectedSensor = this.allStoresData.map(item => item._id);
      } else {
        this.selectedSensor = [];
      }
    }
  },

  async mounted() {
    this.visible = true;
    // this.getStoreType()
    this.getAllStores();
    var data = await resources.getCountries();
    // this.countryOptions = data.countries
    // this.countryOptions = data.countries.filter((c) => c.name == "Japan");
    this.allRegions = data.states;
    this.allCities = data.cities;
  },
  methods: {
    exportMethod() {
      if (this.columns[0].name !== 'export') {
        this.columns.unshift({
          name: 'export',
          align: 'left',
          label: 'Export',
          field: 'Export',
          format: val => `${val}`,
          sortable: true
        });
      }
      this.selectedSensor = [];
      this.exportBtn = !this.exportBtn;
      this.allExport = false;
      if(!this.exportBtn){
        this.columns = this.columns.filter(el => el.name !== 'export');
      }
    },
    downloadCSV() {
      const filterStore = this.allStoresData.filter(item =>
        this.selectedSensor.includes(item._id)
      );
      let mergeArr = [];
      filterStore.forEach(item => {
        let arr = []
        if(item.devices && item.devices.length){
          arr = item.devices.map(e => {return {...e, storeName:item.title, storeCode: item.storeNumber  }});
        }else{
          arr = [{storeName:item.title, storeCode: item.storeNumber  }];
        }
        mergeArr = [...mergeArr, ...arr];
      })
      const csv = this.convertToCSV(mergeArr);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.$store.state.user.translate.sensor +'.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      if (this.columns[0].name === 'export') {
        this.columns = this.columns.filter(el => el.name !== 'export');
      }
      this.exportBtn = false;
      this.allExport = false;
      this.selectedSensor = [];
    },
    convertToCSV(objArray) {
      const translate = this.$store.state.user.translate;
      const csvFileObj = [
        { title: translate.store_name, key: 'storeName' },
        { title: translate.store_number, key: 'storeCode' },
        { title: translate.device_brand, key: 'brand' },
        { title: translate.entrance_name, key: 'entranceName' },
        { title: translate.type, key: 'isConnected' },
        { title: translate.sensor_feature, key: 'features' },
        { title: translate.default_traffic_flow, key: 'defaultTrafficFlow' },
        { title: translate.device_status, key: 'isEnabled' },
        { title: translate.part_number, key: 'partNumber' },
        { title: translate.MAC_address, key: 'macAddress' },
        { title: translate.serial_number, key: 'serialNumber' },
        { title: translate.firmware, key: 'firmwareVersion' },
        { title: translate.IP_address, key: 'ipAddress' },
        { title: translate.subnet_mask, key: 'subnetMask' },
        { title: translate.default_gateway, key: 'defaultGateway' },
        { title: translate.PoE_port, key: 'poePort' },
        { title: translate.dns, key: 'dns' },
        { title: translate.install_position, key: 'installPosition' },
        { title: translate.pathway_depth, key: 'pathwayDepth' },
        { title: translate.detection_length, key: 'detectionLength' },
        { title: translate.zone_location, key: 'location' },
         { title: translate.entrance_number, key: 'entranceId' },
      ];
       
      const array = JSON.parse(JSON.stringify([...objArray]));
      let csv = '';
      // Header
      csv += csvFileObj.map(v => v.title) + '\n';
      // Rows
      let store = this.allStores?.find(
        item => item._id == this.$route.query.id
      );
      array.forEach(obj => {
         let addfullNames = obj['features']?.map(item => 
          item === 'pb' ? 'Passersby': item === 'dm' ? 'Demographics':item === 'dw'? 'Dwelltime': item.charAt(0).toUpperCase() + item.slice(1)
         )
        // add store name and code
        obj['features'] = `"${addfullNames || ''}"`;
        obj['brand'] = `"${obj['brand']?.charAt(0).toUpperCase() + obj['brand']?.slice(1) || ''}"`;
        obj['defaultTrafficFlow'] = `"${obj['defaultTrafficFlow']?.charAt(0).toUpperCase() + obj['defaultTrafficFlow']?.slice(1) || ''}"`;
        // add 0,1 base titles
        obj['isConnected'] =obj['isConnected'] === undefined ? '': obj['isConnected'] === 0
          ? translate.slave
          : translate.master;
        obj['isEnabled'] =
          obj['isEnabled'] === undefined ? '': obj['isEnabled'] === 0 ? translate.disable : translate.enable;
        obj['entranceName'] = obj['entranceName']
          ? '="' + obj['entranceName'] + '"'
          : ''; // for csv string
        obj['serialNumber'] = obj['serialNumber']
          ? '="' + obj['serialNumber'] + '"'
          : ''; // for csv string
          obj['entranceId'] = obj['entranceId']
          ? '="' + obj['entranceId'] + '"'
          : ''; // for csv string
          obj['installPosition'] = obj['installPosition']
          ? '="' + obj['installPosition'] + '"'
          : ''; // for csv string
          obj['location'] = obj['location']
          ? '="' + obj['location'] + '"'
          : ''; // for csv string
        //
         if(!obj['features']?.includes('Traffic')){
          obj['entranceId'] = ''
        }
        csv += csvFileObj.map(v => obj[v.key] || ' ') + '\n';
      });
      return csv;
    },
    getCountryName(code) {
      var name = this.countryOptions.find((c) => c.id == code)?.name;
      return name;
    },
    getRegionName(code) {
      // var name = this.allRegions.find(c => c.prefecture == code)?.prefecture
      return code;
    },
    getCityName(code) {
      // var name = this.allCities.find(c => c.cityId == code)?.cityName
      return code;
    },
    closeModel() {
      this.openModel = false;
    },
    getAllStores() {
      this.visible = true;
      this.loading = true;
      // this.$refs.sensor.visible = true;
      // this.$refs.sensor.loading = true;
      stores
        .getStores()
        .then((response) => {
          this.allStoresData = response.data.response.stores.reverse();
          this.visible = false;
          this.loading = false;
          // this.$refs.sensor.visible = false;
          // this.$refs.sensor.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          // this.$refs.sensor.visible = false;
          // this.$refs.sensor.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    // getStoreType() {
    //   this.visible = true;
    //   this.loading = true;
    //   stores
    //     .getStoreTypes()
    //     .then(response => {
    //       this.allStoresData = response.data.response.stores;
    //       console.log(this.allStoresData, 'all store data')
    //       this.visible = false;
    //       this.loading = false;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.visible = false;
    //       this.loading = false;
    //       if (error.response.data.message) {
    //         this.$q.notify({
    //           message: error.response.data.message,
    //           color: "red"
    //         });
    //       } else {
    //         this.$q.notify({
    //           message: this.$store.state.user.translate
    //             .something_went_wrong,
    //           color: "red"
    //         });
    //       }
    //     });
    // },
    addNewStoreDialog() {
      // console.log("open");
      this.openModel = true;
    },
    editStoreDialog(store) {
      this.selectedStore = store;
      this.openModel = true;
    },
    async updateStore() {
      this.getAllStores();
      this.openModel = false;
    },
    openStoreModel() {
      // this.selectedStore = null;
      // this.openModel = true;
      this.$router.push("/store-details")
    },

    deleteStore(store) {
      this.selectedStore = store;
      this.confirm = true;
    },
    confirmDeleteStore() {
      stores
        .deleteStore(this.selectedStore._id)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.removed_successfully,
            color: "green",
          });
          this.updateStore();

          let { profile } = store.state["user"];
          data.email = profile?.email;
          Vue.prototype?.$mixpanel?.track('webapp_sensor_deleteStore', this.selectedStore);
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
      this.confirm = false;
    },
  },
};
